import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { navigate } from "gatsby";
import React from "react";
import {
  AiFillCaretDown,
  AiFillEnvironment,
  AiFillHeart,
  AiFillMail,
  AiFillPhone,
} from "react-icons/ai";
import { ImFacebook, ImInstagram, ImLinkedin } from "react-icons/im";
import { useApp } from "../context/AppProvider";

const MobileDrawer = () => {
  const { state, dispatch } = useApp();
  const toast = useToast();

  const onCloseDrawer = () => {
    dispatch({ type: "toggleDrawer", isDrawerOpen: false });
  };

  const onNavigate = (url) => {
    navigate(url);
    onCloseDrawer();
  };

  return (
    <Drawer
      trapFocus={false}
      size="full"
      isOpen={state?.isDrawerOpen}
      placement="right"
      onClose={onCloseDrawer}
    >
      <DrawerOverlay>
        <DrawerContent h="100%" bg="#ffffff">
          <DrawerBody
            display="flex"
            flexDirection="column"
            pt="84px"
            pb={8}
            color="#333333"
          >
            <Grid
              pt={4}
              templateRows="repeat(5, auto)"
              rowGap={6}
              justifyContent="flex-start"
            >
              <Menu closeOnSelect closeOnBlur>
                <MenuButton
                  cursor="pointer"
                  display="flex"
                  alignItems="center"
                  fontSize="20px"
                  color="secondary.500"
                >
                  <Text fontWeight="semibold">
                    Services <Icon ml="8px" as={AiFillCaretDown} />
                  </Text>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() =>
                      onNavigate("/services/meeting-and-conferences")
                    }
                  >
                    Meetings & Conferences
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onNavigate("/services/incentive-travel-programs")
                    }
                  >
                    Incentive Travel Programs
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/services/exhibit-management")}
                  >
                    Exhibit Management
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onNavigate("/services/virtual-event-experiences")
                    }
                  >
                    Virtual Event Experiences
                  </MenuItem>
                </MenuList>
              </Menu>

              <Text
                cursor="pointer"
                display="flex"
                alignItems="center"
                fontSize="20px"
                color="secondary.500"
                onClick={() => onNavigate("/why-aevias")}
                fontWeight="semibold"
              >
                Why Aevias?
              </Text>

              <Text
                cursor="pointer"
                display="flex"
                alignItems="center"
                fontSize="20px"
                color="secondary.500"
                onClick={() => onNavigate("/insights")}
                fontWeight="semibold"
              >
                Insights
              </Text>
              <Text
                cursor="pointer"
                display="flex"
                alignItems="center"
                fontSize="20px"
                color="secondary.500"
                onClick={() => onNavigate("/careers")}
                fontWeight="semibold"
              >
                Careers
              </Text>
              <Text
                cursor="pointer"
                display="flex"
                alignItems="center"
                fontSize="20px"
                color="secondary.500"
                onClick={() => onNavigate("/contact-us")}
                fontWeight="semibold"
              >
                Contact us
              </Text>
            </Grid>
            <Box mt="auto">
              <Grid
                templateColumns="repeat(3, 1fr)"
                columnGap="40px"
                gridTemplateColumns={{ base: "none", md: "repeat(3, 1fr)" }}
                gridTemplateRows={{ base: "auto auto auto", md: "none" }}
                gridRowGap={{ base: "16px", md: 0 }}
              >
                <Box
                  color="secondary.500"
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillEnvironment} /> Location
                  </Text>
                  <Text
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                  >
                    58 E 2nd Ave, Vancouver, BC V5T 1B1
                  </Text>
                </Box>

                <Box
                  color="secondary.500"
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillMail} /> Email
                  </Text>
                  <Text
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                  >
                    info@aevias.com
                  </Text>
                </Box>
                <Box
                  color="secondary.500"
                  borderBottom={{ base: "1px solid lightgray", md: "none" }}
                  borderLeft={{ base: "none", md: "1px solid lightgray" }}
                  pl={{ base: 0, md: "16px" }}
                  pb={{ base: 4, md: 0 }}
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillPhone} /> Call Us
                  </Text>
                  <Text
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    fontSize="md"
                  >
                    604-566-8031 (Vancouver)
                    <br />
                    905-806-1767 (Toronto)
                  </Text>
                </Box>
                <Box
                  color="secondary.500"
                  pl={{ base: 0, md: "16px" }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text display="flex" alignItems="center">
                    <Icon mr="8px" as={AiFillHeart} /> Follow Us
                  </Text>
                  <Flex alignItems="center">
                    <Icon
                      mr={6}
                      fontSize="20px"
                      as={ImFacebook}
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/AeviasEvents",
                          "_blank"
                        )
                      }
                    />
                    <Icon
                      mr={6}
                      fontSize="20px"
                      as={ImLinkedin}
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/aeviasevents/",
                          "_blank"
                        )
                      }
                    />
                    <Icon
                      fontSize="20px"
                      as={ImInstagram}
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://instagram.com/aeviasevents?utm_medium=copy_link",
                          "_blank"
                        )
                      }
                    />
                  </Flex>
                </Box>
              </Grid>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MobileDrawer;
