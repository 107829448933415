import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useApp } from "../context/AppProvider";

import "./styles/header.scss";

const Header = ({ location }) => {
  const { dispatch, state } = useApp();
  const [colored, setColored] = useState(false);
  const toast = useToast();

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function HeaderScroll() {
      const currentScrollPos = window.pageYOffset;

      if (document.getElementById("header")) {
        if (prevScrollpos > currentScrollPos && currentScrollPos > 480) {
          document.getElementById("header").style.top = "0";
          document.getElementById("header").style.boxShadow =
            "rgba(0, 0, 0, 0.14) 0px 2px 8px";
          document.getElementById("header").style.backgroundColor = "#ffffff";
          setColored(true);
        } else {
          document.getElementById("header").style.top = "-154px";
        }

        if (currentScrollPos < 100) {
          setColored(false);
          document.getElementById("header").style.boxShadow = "none";
          document.getElementById("header").style.top = "0";
          document.getElementById("header").style.backgroundColor =
            "transparent";
        }
      }

      prevScrollpos = currentScrollPos;
    };
  }, []);

  return (
    <Box
      id="header"
      className="header"
      position="fixed"
      top={0}
      width="100%"
      as="header"
      zIndex="99"
      display="flex"
      flexDir="column"
      transition="top 0.4s ease-in-out"
    >
      <Box
        px={{ base: "16px", md: "2rem", lg: "6rem" }}
        py="24px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          tabIndex="0"
          onClick={() => navigate("/")}
          onKeyDown={() => navigate("/")}
          onDragStart={(e) => e.preventDefault()}
          role="button"
          display={state?.isDrawerOpen ? "none" : colored && "none"}
          height={{ base: "28px", sm: "32px", md: "36px", lg: "40px" }}
          cursor="pointer"
        >
          <img
            src="/img/aevias-logo.png"
            alt="Aevias Logo"
            style={{ height: "100%" }}
          />
        </Box>
        <Box
          display={state?.isDrawerOpen ? "block" : !colored && "none"}
          height={{ base: "28px", sm: "32px", md: "36px", lg: "40px" }}
          tabIndex="0"
          onClick={() => navigate("/")}
          onKeyDown={() => navigate("/")}
          onDragStart={(e) => e.preventDefault()}
          role="button"
          cursor="pointer"
        >
          <img
            src="/img/aevias-logo-color.png"
            alt="Aevias Logo"
            style={{ height: "100%" }}
          />
        </Box>
        <Box
          onClick={() =>
            dispatch({
              type: "toggleDrawer",
              isDrawerOpen: !state?.isDrawerOpen,
            })
          }
          id="nav-icon"
          className={state?.isDrawerOpen ? "open" : null}
          display={{ base: "initial", md: "none" }}
        >
          <span
            style={{
              backgroundColor:
                colored || state?.isDrawerOpen ? "#2C348D" : "#ffffff",
            }}
          />
          <span
            style={{
              backgroundColor:
                colored || state?.isDrawerOpen ? "#2C348D" : "#ffffff",
            }}
          />
          <span
            style={{
              backgroundColor:
                colored || state?.isDrawerOpen ? "#2C348D" : "#ffffff",
            }}
          />
        </Box>
        <Box display={{ base: "none", md: "flex" }}>
          <Menu closeOnSelect closeOnBlur>
            <MenuButton
              cursor="pointer"
              display="flex"
              alignItems="center"
              fontSize={{ base: "16px", lg: "18px" }}
              color={colored ? "secondary.500" : "#ffffff"}
              ml="8"
            >
              Services <Icon ml="8px" as={AiFillCaretDown} />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => navigate("/services/meeting-and-conferences")}
              >
                Meetings & Conferences
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/services/incentive-travel-programs")}
              >
                Incentive Travel Programs
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/services/exhibit-management")}
              >
                Exhibit Management
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/services/virtual-event-experiences")}
              >
                Virtual Event Experiences
              </MenuItem>
              {/* <MenuItem
                onClick={() =>
                  toast({
                    title: "Virtual Rewards Marketplace coming soon!",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                  })
                }
              >
                Virtual Marketplace
              </MenuItem> */}
            </MenuList>
          </Menu>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color={colored ? "secondary.500" : "#ffffff"}
            ml="8"
            onClick={() => navigate("/why-aevias")}
          >
            Why Aevias?
          </Text>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color={colored ? "secondary.500" : "#ffffff"}
            ml="8"
            onClick={() => navigate("/insights")}
          >
            Insights
          </Text>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color={colored ? "secondary.500" : "#ffffff"}
            ml="8"
            onClick={() => navigate("/careers")}
          >
            Careers
          </Text>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color={colored ? "secondary.500" : "#ffffff"}
            ml="8"
            onClick={() => navigate("/contact-us")}
          >
            Contact us
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
