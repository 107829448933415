import React from "react";
import { Icon } from "@chakra-ui/react";

const FacebookIcon = (props) => (
  <Icon width="40px" height="40px" viewBox="0 0 48 48" {...props}>
    <path
      d="M48 24C48 10.7438 37.2562 0 24 0C10.7438 0 0 10.7438 0 24C0 37.2562 10.7438 48 24 48C24.1406 48 24.2812 48 24.4219 47.9906V29.3156H19.2656V23.3063H24.4219V18.8812C24.4219 13.7531 27.5531 10.9594 32.1281 10.9594C34.3219 10.9594 36.2062 11.1188 36.75 11.1938V16.5563H33.6C31.1156 16.5563 30.6281 17.7375 30.6281 19.4719V23.2969H36.5813L35.8031 29.3063H30.6281V47.0719C40.6594 44.1938 48 34.9594 48 24Z"
      fill="#333333"
    />
  </Icon>
);

const InstagramIcon = (props) => (
  <Icon width="40px" height="40px" viewBox="0 0 48 48" {...props}>
    <path
      d="M28.5938 24C28.5938 26.5371 26.5371 28.5938 24 28.5938C21.4629 28.5938 19.4062 26.5371 19.4062 24C19.4062 21.4629 21.4629 19.4062 24 19.4062C26.5371 19.4062 28.5938 21.4629 28.5938 24Z"
      fill="#333333"
    />
    <path
      d="M34.7432 15.8724C34.5223 15.274 34.17 14.7324 33.7123 14.2878C33.2677 13.8301 32.7264 13.4778 32.1277 13.257C31.6421 13.0684 30.9126 12.8439 29.569 12.7827C28.1155 12.7164 27.6797 12.7021 24 12.7021C20.3199 12.7021 19.8842 12.7161 18.431 12.7823C17.0874 12.8439 16.3575 13.0684 15.8723 13.257C15.2736 13.4778 14.7319 13.8301 14.2877 14.2878C13.83 14.7324 13.4777 15.2737 13.2565 15.8724C13.0679 16.358 12.8434 17.0879 12.7822 18.4315C12.7159 19.8846 12.7017 20.3204 12.7017 24.0005C12.7017 27.6802 12.7159 28.116 12.7822 29.5695C12.8434 30.9131 13.0679 31.6426 13.2565 32.1282C13.4777 32.7269 13.8296 33.2682 14.2874 33.7128C14.7319 34.1705 15.2732 34.5228 15.8719 34.7436C16.3575 34.9326 17.0874 35.1571 18.431 35.2183C19.8842 35.2845 20.3196 35.2985 23.9996 35.2985C27.6801 35.2985 28.1158 35.2845 29.5686 35.2183C30.9122 35.1571 31.6421 34.9326 32.1277 34.7436C33.3296 34.28 34.2795 33.3301 34.7432 32.1282C34.9318 31.6426 35.1562 30.9131 35.2178 29.5695C35.2841 28.116 35.298 27.6802 35.298 24.0005C35.298 20.3204 35.2841 19.8846 35.2178 18.4315C35.1566 17.0879 34.9321 16.358 34.7432 15.8724ZM24 31.0768C20.0914 31.0768 16.923 27.9087 16.923 24.0001C16.923 20.0916 20.0914 16.9235 24 16.9235C27.9082 16.9235 31.0767 20.0916 31.0767 24.0001C31.0767 27.9087 27.9082 31.0768 24 31.0768ZM31.3564 18.2975C30.4431 18.2975 29.7026 17.557 29.7026 16.6437C29.7026 15.7303 30.4431 14.9899 31.3564 14.9899C32.2698 14.9899 33.0103 15.7303 33.0103 16.6437C33.0099 17.557 32.2698 18.2975 31.3564 18.2975Z"
      fill="#333333"
    />
    <path
      d="M24 0C10.7472 0 0 10.7472 0 24C0 37.2528 10.7472 48 24 48C37.2528 48 48 37.2528 48 24C48 10.7472 37.2528 0 24 0ZM37.6981 29.6818C37.6315 31.1488 37.3982 32.1504 37.0576 33.0271C36.3417 34.8783 34.8783 36.3417 33.0271 37.0576C32.1508 37.3982 31.1488 37.6311 29.6821 37.6981C28.2125 37.7651 27.743 37.7812 24.0004 37.7812C20.2573 37.7812 19.7882 37.7651 18.3182 37.6981C16.8516 37.6311 15.8496 37.3982 14.9733 37.0576C14.0533 36.7115 13.2206 36.1692 12.5321 35.4679C11.8312 34.7798 11.2888 33.9467 10.9427 33.0271C10.6022 32.1508 10.3689 31.1488 10.3022 29.6821C10.2345 28.2122 10.2188 27.7427 10.2188 24C10.2188 20.2573 10.2345 19.7878 10.3019 18.3182C10.3685 16.8512 10.6014 15.8496 10.942 14.9729C11.2881 14.0533 11.8308 13.2202 12.5321 12.5321C13.2202 11.8308 14.0533 11.2885 14.9729 10.9424C15.8496 10.6018 16.8512 10.3689 18.3182 10.3019C19.7878 10.2349 20.2573 10.2188 24 10.2188C27.7427 10.2188 28.2122 10.2349 29.6818 10.3022C31.1488 10.3689 32.1504 10.6018 33.0271 10.942C33.9467 11.2881 34.7798 11.8308 35.4683 12.5321C36.1692 13.2206 36.7119 14.0533 37.0576 14.9729C37.3986 15.8496 37.6315 16.8512 37.6985 18.3182C37.7655 19.7878 37.7812 20.2573 37.7812 24C37.7812 27.7427 37.7655 28.2122 37.6981 29.6818Z"
      fill="#333333"
    />
  </Icon>
);

const LinkedinIcon = (props) => (
  <Icon width="40px" height="40px" viewBox="0 0 48 48" {...props}>
    <path
      d="M24 0C10.7472 0 0 10.7472 0 24C0 37.2528 10.7472 48 24 48C37.2528 48 48 37.2528 48 24C48 10.7472 37.2528 0 24 0ZM17.0259 36.2812H11.1808V18.6962H17.0259V36.2812ZM14.1035 16.2949H14.0654C12.104 16.2949 10.8354 14.9447 10.8354 13.2572C10.8354 11.5316 12.1428 10.2188 14.1423 10.2188C16.1418 10.2188 17.3723 11.5316 17.4104 13.2572C17.4104 14.9447 16.1418 16.2949 14.1035 16.2949ZM38.1021 36.2812H32.2577V26.8737C32.2577 24.5094 31.4114 22.897 29.2965 22.897C27.6819 22.897 26.7202 23.9846 26.2976 25.0345C26.1431 25.4103 26.1053 25.9354 26.1053 26.4609V36.2812H20.2606C20.2606 36.2812 20.3372 20.3459 20.2606 18.6962H26.1053V21.186C26.8821 19.9878 28.2719 18.2834 31.3729 18.2834C35.2185 18.2834 38.1021 20.7968 38.1021 26.198V36.2812Z"
      fill="#333333"
    />
  </Icon>
);

const LinkedinIconTransparent = (props) => (
  <Icon width="40px" height="40px" viewBox="0 0 48 48" {...props}>
    <path d="M24 0C10.7472 0 0 10.7472 0 24C0 37.2528 10.7472 48 24 48C37.2528 48 48 37.2528 48 24C48 10.7472 37.2528 0 24 0ZM17.0259 36.2812H11.1808V18.6962H17.0259V36.2812ZM14.1035 16.2949H14.0654C12.104 16.2949 10.8354 14.9447 10.8354 13.2572C10.8354 11.5316 12.1428 10.2188 14.1423 10.2188C16.1418 10.2188 17.3723 11.5316 17.4104 13.2572C17.4104 14.9447 16.1418 16.2949 14.1035 16.2949ZM38.1021 36.2812H32.2577V26.8737C32.2577 24.5094 31.4114 22.897 29.2965 22.897C27.6819 22.897 26.7202 23.9846 26.2976 25.0345C26.1431 25.4103 26.1053 25.9354 26.1053 26.4609V36.2812H20.2606C20.2606 36.2812 20.3372 20.3459 20.2606 18.6962H26.1053V21.186C26.8821 19.9878 28.2719 18.2834 31.3729 18.2834C35.2185 18.2834 38.1021 20.7968 38.1021 26.198V36.2812Z" />
  </Icon>
);

export { FacebookIcon, InstagramIcon, LinkedinIcon, LinkedinIconTransparent };
