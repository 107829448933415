import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { navigate } from "gatsby-link";
import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "./SocialIcons";

const Footer = () => {
  return (
    <Box id="footer" as="footer" bgColor="#F2F2F2" py="24">
      <Box
        display="flex"
        flexDir="column"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          height={{ base: "28px", sm: "32px", md: "36px", lg: "40px" }}
          mb="8"
          onDragStart={(e) => e.preventDefault()}
          onClick={() => navigate("/")}
          role="button"
          cursor="pointer"
        >
          <img
            src="/img/aevias-logo-color.png"
            alt="Aevias Logo"
            style={{ height: "100%" }}
          />
        </Box>
        <Box
          display={{ base: "flex", md: "grid" }}
          flexWrap="wrap"
          width={{ base: "80%", md: "100%" }}
          gridTemplateColumns={{
            base: "repeat(2, auto)",
            md: "repeat(7, auto)",
          }}
          gridTemplateRows={{ base: "auto", md: "none" }}
          gridColumnGap={{ base: "24px", md: "2rem" }}
          gridRowGap={{ base: "24px", md: 0 }}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          mb="8"
        >
          <Menu closeOnSelect closeOnBlur>
            <MenuButton
              cursor="pointer"
              display="flex"
              alignItems="center"
              fontSize={{ base: "16px", lg: "18px" }}
              color="#000"
              ml="8"
            >
              Services <Icon ml="8px" as={AiFillCaretDown} />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => navigate("/services/meeting-and-conferences")}
              >
                Meetings & Conferences
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/services/incentive-travel-programs")}
              >
                Incentive Travel Programs
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/services/exhibit-management")}
              >
                Exhibit Management
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/services/virtual-event-experiences")}
              >
                Virtual Event Experiences
              </MenuItem>
              {/* <MenuItem
                onClick={() =>
                  toast({
                    title: "Virtual Rewards Marketplace coming soon!",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                  })
                }
              >
                Virtual Marketplace
              </MenuItem> */}
            </MenuList>
          </Menu>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color="#333333"
            ml={{ base: 0, md: 8 }}
            onClick={() => navigate("/why-aevias")}
          >
            Why Aevias?
          </Text>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color="#333333"
            ml={{ base: 0, md: 8 }}
            onClick={() => navigate("/insights")}
          >
            Insights
          </Text>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color="#333333"
            ml={{ base: 0, md: 8 }}
            onClick={() => navigate("/careers")}
          >
            Careers
          </Text>

          <Text
            cursor="pointer"
            display="flex"
            alignItems="center"
            fontSize={{ base: "16px", lg: "18px" }}
            color="#333333"
            ml={{ base: 0, md: 8 }}
            onClick={() => navigate("/contact-us")}
          >
            Contact us
          </Text>
        </Box>

        <Box mb="12">
          <Text fontSize="16px" lineHeight="24px" color="#333333" mb="4">
            Look for us on our social channels
          </Text>
          <Box>
            <LinkedinIcon
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/aeviasevents/",
                  "_blank"
                )
              }
              cursor="pointer"
            />
            <InstagramIcon
              onClick={() =>
                window.open(
                  "https://instagram.com/aeviasevents?utm_medium=copy_link",
                  "_blank"
                )
              }
              cursor="pointer"
              mx="8"
            />
            <FacebookIcon
              onClick={() =>
                window.open("https://www.facebook.com/AeviasEvents", "_blank")
              }
              cursor="pointer"
            />
          </Box>
        </Box>

        <Box>
          <Text
            textDecor="underline"
            fontSize="16px"
            lineHeight="24px"
            color="#333333"
            mb="4"
          >
            Privacy and Terms & Conditions
          </Text>
          <Text fontSize="16px" lineHeight="24px" color="#333333" mb="4">
            © 2021 Aevias.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
